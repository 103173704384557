<template>
  <div>
    <div>
      <a-row :gutter="12">
        <a-col :span="16">
          <a-card title="日称重人次趋势" :bodyStyle="{padding:0}">
            <div style="height: 340px;" ref="dayCountTrendChart"></div>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="日称重次数分布" :bodyStyle="{padding:0}">
            <div style="height: 340px;" ref="dayCountDistributionChart"></div>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div style="margin-top: 20px;">
      <div>
        <a-card title="用户增长趋势" :bodyStyle="{padding:0}">
          <div style="height: 280px;" ref="userCountChart"></div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { loadWeighCountTrendData, loadWeighCountDistributionData, loadUserCountTrendData } from '@/api/pc'
import echarts from 'echarts'
export default {
  name: 'MainChart',
  data () {
    return {
      dayCountQueryDay: 30,
      userCountQueryDay: 30
    }
  },
  created () {
    this.$nextTick(function () {
      this.weighCountDayData()
      this.weighCountdDistributionData()
      this.userCountTrendData()
    })
  },
  methods: {
    weighCountDayData () {
      loadWeighCountTrendData({ queryDay: this.dayCountQueryDay }).then(res => {
         if (res.code === 0) {
           var data = res.data
           var chart = echarts.init(this.$refs['dayCountTrendChart'])
           var option = {
             grid: {
               top: 20,
               left: 40,
               right: 40,
               bottom: 40
             },
             xAxis: {
               type: 'category',
               data: data.xData
             },
             yAxis: {
               type: 'value'
             },
             series: [
               {
                 data: data.yData,
                 type: 'line',
                 smooth: true,
                 lineStyle: {
                   color: '#00CC99'
                 }
               }
             ]
           }
           chart.setOption(option)
         }
      })
    },
    weighCountdDistributionData () {
      loadWeighCountDistributionData({ queryDay: this.dayCountQueryDay }).then(res => {
        if (res.code === 0) {
          var data = res.data
          var chart = echarts.init(this.$refs['dayCountDistributionChart'])
          var option = {
            grid: {
              top: 20,
              left: 40,
              right: 40,
              bottom: 40
            },
            xAxis: {
              type: 'category',
              data: data.xData
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: data.yData,
                type: 'bar',
                barWidth: 16,
                itemStyle: {
                  normal: {
                    barBorderRadius: [8, 8, 0, 0],
                    color: '#00CC99'
                  }
                }
              }
            ]
          }
          chart.setOption(option)
        }
      })
    },
    userCountTrendData () {
      loadUserCountTrendData({ queryDay: this.userCountQueryDay }).then(res => {
        if (res.code === 0) {
          var data = res.data
          var chart = echarts.init(this.$refs['userCountChart'])
          var option = {
            grid: {
              top: 20,
              left: 40,
              right: 40,
              bottom: 40
            },
            xAxis: {
              type: 'category',
              data: data.xData
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: data.yData,
                type: 'line',
                smooth: true,
                lineStyle: {
                  color: '#00CC99'
                }
              }
            ]
          }
          chart.setOption(option)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
